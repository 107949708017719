
import { Vue, Options, prop } from "vue-class-component";
import copy from "copy-text-to-clipboard";
import {
  convertHtmlToPlain,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import term from "./term";

class Props {
  referralCode = prop<string>({
    type: String,
    default: ""
  });
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class ReferralModal extends Vue.with(Props) {
  onCloseModal() {
    this.$emit("update:modelValue", false);
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
  }

  get term() {
    return term({
      period: "1 Maret - 31 Desember 2025",
      insentif: "5%",
      topUp: formatPrice(10000000),
      incentive: formatPrice(500000),
      code: this.referralCode,
      paidPeriod: "M+1",
      phone: "0815-7246-7236"
    });
  }

  copyNotification = false;
  autoCopy() {
    const contentCopy =
      "Hai, Punya mimpi untuk punya usaha pengiriman sendiri? dan bisa dapat komisi ratusan juta tiap bulan?\n\n" +
      "Ayo bergabung menjadi Agen Mitra POS Lion Parcel, modal kecil CUAN BESAR!\n\n" +
      "Kenapa harus Lion Parcel?\n" +
      "- Proses mudah dan cepat! Hanya 10 hari!\n" +
      "- Komisi besar untuk setiap agen\n" +
      "- Harga ongkir kompetitif\n" +
      "- Lion Parcel sudah tersebar di seluruh Indonesia\n\n" +
      `Daftar Sekarang menjadi Agen Mitra POS Lion Parcel. Klik disini untuk mendaftar https://lionparcel.com/agen/perorangan-app?referral=${this.referralCode} dan masukkan kode referral ${this.referralCode}`;
    copy(convertHtmlToPlain(this.referralCode));
    this.copyNotification = true;
  }

  removeCopyNotification() {
    this.copyNotification = false;
  }
}
