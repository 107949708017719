const term = (info: any) => {
  return `<ul style="list-style-type:disc">
        <li>Program POS Referral ini berlaku di bulan <strong>${info.period}</strong>.</li>
        <li>Agen Mitra POS yang berhasil mengajak teman untuk bergabung dan melakukan Top Up senilai <b>${info.topUp}</b> maka akan mendapatkan insentif sebesar ${info.incentive}.</li>
        <li>POS/Konsol yang memberikan referral atau yang direferralkan akan sama-sama mendapatkan saldo tambahan <b>${info.incentive}</b>.</li>
        <li>Insentif akan dibayarkan dalam jangka waktu H+1 bulan dalam bentuk Saldo POS (<strong>Program Referral ${info.incentive}</strong>).</li>
        <li>POS/Konsol yang mereferralkan akan mendapatkan cashback <b>${info.insentif}</b> dari total ongkir POS baru yang direferralkan selama 3 bulan pertama, terhitung sejak bulan pertama POS baru melakukan top-up.</li>
        <li>Skema New Referral Cashback hanya berlaku untuk <b>POS Reguler</b> dan tidak berlaku untuk POS dengan kategori free branding.</li>
        <li>Cut off pencairan <b>cashback ${info.insentif}</b> adalah H+14 hari kerja setelah 3 bulan pertama, terhitung sejak bulan pertama POS baru melakukan top-up.</li>
        <li>Semua jenis pencairan insentif akan masuk ke saldo Genesis.</li>
        <li><b>Syarat untuk mendapatkan insentif yaitu: </b></li>
            <ul style="list-style-type: '-'; padding-left: 10px;">
                <li style="padding-left: 8px;"> Calon Agen Mitra POS wajib memilih Rekomendasi dari Agen POS Lain pada pertanyaan Darimana Anda mendapatkan informasi tentang pendaftaran ini.</li>
                <li style="padding-left: 8px;"> Mengisi Kode Referral  <b>“POS Code”</b>, contoh: <b>${info.code}</b> pada form perorangan / perusahaan di website Lion Parcel. </li>
                <li style="padding-left: 8px;"> Lokasi POS telah disetujui oleh tim Lion Parcel. </li>
            </ul>
        <li>Insentif akan dihitung setelah Agen Mitra POS yang diajak sudah disetujui oleh Tim Lion Parcel dan telah melakukan Top Up saldo awal sebesar <b>${info.topUp}</b> dalam tempo waktu yang telah ditentukan.</li>
        <li>Jika POS/Konsol yang mereferralkan terbukti melakukan tindakan manipulatif, seperti mengalihkan pengiriman untuk mempengaruhi insentif, akun mereka akan dibekukan, dan insentif akan dicabut.</li>
        <li>Tim NPOS akan melaporkan transaksi-transaksi yang terjadi kepada Tim Finance sesuai dengan aturan yang berlaku.</li>
        <li>Informasi lebih lanjut tentang program ini. Anda bisa menghubungi di NPOS Development (+62 819-3765-1551) / Salsabila (+62 857-8282-5338)</li>
        <li>Bagikan Kode Referral Anda kepada Calon Agen Mitra POS dengan menyalin link perorangan: <a href="https://lionparcel.com/agen/perorangan-app?referral=${info.code}" target="_blank" style="color: red" class="underline">https://lionparcel.com/agen/perorangan-app?referral=${info.code}</a> atau menyalin link perusahaan: <a href="https://lionparcel.com/agen/perusahaan-app?referral=${info.code}" target="_blank" style="color: red" class="underline">https://lionparcel.com/agen/perusahaan-app?referral=${info.code}</a>. Silahkan daftarkan Calon Agen Mitra POS Anda dengan klik tombol dibawah ini:</li>
    </ul>`;
};

export default term;
